<template>
  <div>
      <Loader v-if="loading"></Loader>
      <CRow v-else>
          <CCol lg="12">
              <AbstractTable :fields="fields" :items="diseases" :actions="actions" title="Enfermedades"
                  labelItemPerPage="Enfermedades por página:"
                  :addBtn="{ enable: $store.state.isAdmin, text: 'Añadir enfermedad', event: 'addElement' }" @addElement="addDisease"
                  @editInfo="editDisease" @showDeleteModal="handleModal" :noResults="'No se han encontrado enfermedades'"/>
                  
             <DeleteModal :modalContent="modalContent" @delete="deleteDiseaseData(diseaseId)" :showModal="deleteModal" @closeModal="closeModal" />
          </CCol>
      </CRow>
  </div>
</template>

<script>
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from "@/components/Loader.vue";
import { getDiseasesData, deleteDisease } from '@/services/diseases';
import { generateField } from '@/utils/utils';
export default {
  name: "questTable",
  components: { AbstractTable, DeleteModal, Loader },
  data() {
      return {
          diseases: [],
          disease: {},
          diseaseId: 0,
          diseaseName: '',
          loading: true,
          deleteModal: false,
          fields: [
              generateField("name", "Nombre", {styleData: { textAlign: 'center'}}),
              generateField("description", "Descripción de la enfermedad"),
              generateField("actions", 'Acciones', { sorter: false, filter: false })
          ],
          actions: [
              {
                  key: 'info',
                  label: 'Información',
                  event: 'editInfo',
                  classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
              },
              {
                  key: 'delete',
                  label: 'Eliminar',
                  isAdmin: !this.$store.state.isAdmin,
                  event: 'showDeleteModal',
                  classButton: 'btn btn-delete btn-sm btn-pill'
              },
          ],
          modalContent: {
          title: 'Eliminar enfermedad',
          textBody: '',
          buttons: [
              {
                textButton: 'Cerrar',
                color: 'secondary',
                event: 'closeModal'
              },
              {
                textButton: 'Aceptar',
                color: 'danger',
                event: 'delete'
              },
          ]
    }
   }
  },
  created() {
     this.getDiseases();
  },
  methods: {
      /**
       * Función en la que se obtienen las enfermedades
       * 
       * @returns {object[]} Las enfermedades obtenidas.
       */
       getDiseases() {
          getDiseasesData()
              .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
              .then((result) => {
              this.diseases = result
              this.diseases.forEach((disease) => {
                  disease.id = disease.id
                  disease.name = disease.name
                  disease.description = disease.desc
              })
              this.loading = false;
          });
      },
      /**
       * Este método se llama cuando el usuario pulsa el botón Crear nueva enfermedad y redirige a la vista con el formulario para poder crear una.
       */
      addDisease() {
          this.$router.push(`/diseases/create/`);
      },
       /**
        * Método para controlar lo que se muestra en el Modal de Eliminar la enfermedad.
        */
      handleModal(item) {
          this.diseaseId = item.id;
          this.modalContent.textBody = `¿Está seguro de que desea eliminar la enfermedad: <strong>"${item.name}</strong>"? Esta acción no se puede deshacer.`,
          this.deleteModal = true;
      },
      /**
       * Este método se llama cuando el usuario pulsa el botón Editar y redirige a la vista con el formulario para poder editar una.
       */
      editDisease(item) {
          this.$router.push(`/diseases/info/${item.id}`)
      },
      /**
       * Este metodo eliminará la enfermedad, mandando por la URL el id
       * 
       * @param {number} id - El id de la enfermedad a eliminar
       */
       deleteDiseaseData(id) {
          deleteDisease(id).then(() => {
              this.getDiseases()
          })
          this.deleteModal = false;
      },
       /**
       * Función para cerrar el Modal.
       */
      closeModal(){
          this.deleteModal = false;
      }
  },
};
</script>